import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bbeca02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang-switcher" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_fa_icon, {
      class: "lang-switcher__icon mr-2",
      icon: "language",
      size: "2x"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (name, code) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["lang-switcher__button px-2 py-1", {'lang-switcher__button--current':_ctx.isCurrent(code)}]),
        key: code,
        onClick: ($event: any) => (_ctx.switchCurrentLanguage(code))
      }, _toDisplayString(name), 11, _hoisted_2))
    }), 128))
  ]))
}