import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047a191a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_fa_icon, {
      class: "loader__icon",
      icon: "circle-notch",
      size: _ctx.iconSize,
      spin: ""
    }, null, 8, ["size"]),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["loader__text", ['text-'+_ctx.textSize]])
        }, _toDisplayString(_ctx.text), 3))
      : _createCommentVNode("", true)
  ]))
}