import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Cameras from '@/views/Cameras.vue';
// import Camera from '@/views/Camera.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cameras/:id',
    alias: '/',
    name: 'cameras',
    component: () => Promise.resolve(Cameras),
  },
  {
    path: '/polygon/:polygonId',
    name: 'polygon',
    component: () => Promise.resolve(Cameras),
  },
  {
    path: '/polygon/:polygonId/:id',
    name: 'polygon-cameras',
    component: () => Promise.resolve(Cameras),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
