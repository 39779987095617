import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aca907ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "alert my-4 text-center",
  role: "alert"
}
const _hoisted_3 = {
  href: "https://www.google.ru/chrome/",
  target: "_blank"
}
const _hoisted_4 = {
  key: 1,
  class: "flex py-24 justify-center items-center"
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "list-of-cameras border-2 overflow-y-auto mr-4 p-3 rounded" }
const _hoisted_7 = { class: "camera-preview__title" }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "stream" }
const _hoisted_10 = {
  key: 0,
  class: "stream__title"
}
const _hoisted_11 = {
  key: 1,
  class: "stream__loader-container"
}
const _hoisted_12 = {
  ref: "video",
  class: "rounded",
  width: "1280",
  height: "720",
  playsinline: "",
  autoplay: "",
  muted: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_loader = _resolveComponent("loader")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isWebRtcSupported)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_i18n_t, { keypath: "cameras.alert.webrtc-isnot-supported" }, {
            link: _withCtx(() => [
              _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('cameras.alert.webrtc-isnot-supported.google-chrome-link-text')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_loader, {
            text: _ctx.$t('components.loading.text')
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfStreams, (stream) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "mb-2",
              key: stream.id
            }, [
              _createVNode(_component_router_link, {
                class: _normalizeClass(["camera-preview", {'camera-preview--selected':_ctx.isStreamSelected(stream.id)}]),
                to: {name:'cameras', params:{id: stream.id}},
                onClick: ($event: any) => (_ctx.handleCameraClick(stream))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_fa_icon, {
                      class: "camera-preview__icon",
                      icon: "video"
                    }),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(stream.description), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["class", "to", "onClick"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_ctx.selectedStream)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.selectedStream.description), 1))
            : _createCommentVNode("", true),
          (_ctx.isStreamLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_loader, {
                  class: "stream__loader",
                  "icon-size": "2x",
                  text: _ctx.$t('cameras.stream.loading')
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("video", _hoisted_12, null, 512), [
            [_vShow, !_ctx.isStreamLoading]
          ])
        ])
      ])
    ], 512), [
      [_vShow, !_ctx.isLoading]
    ])
  ]))
}