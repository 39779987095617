import { ProfileDto } from '@/types/ProfileDto';

export class Profile implements ProfileDto {
  constructor(
    readonly video_group: number,
    readonly token: string,
    readonly login: string,
  ) {
  }
}
