
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loader',
  props: {
    iconSize: {
      type: String,
      default: '5x',
    },
    textSize: {
      type: String,
      default: 'xl',
    },
    text: {
      type: String,
      default: '',
    },
  },
});
