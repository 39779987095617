import CryptoJS from 'crypto-js/core';
import md5 from 'crypto-js/md5';
import { ProfileDto } from '@/types/ProfileDto';
import { HttpError, HttpTransport } from '@/services/HttpTransport';
import { CookiesStorage } from '@/services/CookiesStorage';
import { Profile } from '@/types/entities/Profile';

export function mkAuthToken(login: string, passwod: string): string {
  return md5(`login:${passwod}:voltbro`).toString(CryptoJS.enc.Hex);
  // return md5(`${login}:${passwod}:voltbro`).toString(CryptoJS.enc.Hex);
}

export class AuthenticationService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private httpTransport: HttpTransport, private cookiesStorage: CookiesStorage) {
  }

  async check(login: string, password: string): Promise<Profile | null> {
    const token = mkAuthToken(login, password);
    try {
      return await this.loadProfile(token, login);
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.errorCode() === 404) {
          return null;
        }
      }
      throw e;
    }
  }

  saveInCookies(profile: Profile): Promise<void> {
    this.cookiesStorage.set('profile', JSON.stringify(profile), 30);
    return Promise.resolve();
  }

  async checkCookieAuth(): Promise<Profile | null> {
    const profileData = this.cookiesStorage.get('profile');
    if (!profileData) {
      return null;
    }

    const { token, login } = JSON.parse(profileData);
    if (!token || !login) {
      return null;
    }
    return this.loadProfile(token, login);
  }

  async exit() {
    this.cookiesStorage.remove('profile');
  }

  private async loadProfile(token: string, login: string): Promise<Profile> {
    const profile = await this.httpTransport
      .get<ProfileDto>(`/auth/${encodeURIComponent(token)}/${encodeURIComponent(login)}.json`);
    return new Profile(profile.video_group, token, login);
  }
}
