
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LangSwitcher',
  methods: {
    switchCurrentLanguage(code: string) {
      this.$i18n.locale = code;
    },
    isCurrent(code: string) {
      return this.$i18n.locale === code;
    },
  },
  setup() {
    const languages = {
      'en-us': 'English',
      'ru-ru': 'Русский',
    };

    return {
      // switchCurrentLanguage,
      languages,
    };
  },
});
