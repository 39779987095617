// eslint-disable-next-line max-classes-per-file
export class HttpError extends Error {
  readonly response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }

  errorCode(): number {
    return this.response.status;
  }

  errorText(): string {
    return this.response.statusText;
  }
}

export class HttpTransport {
  readonly baseHost: string;

  constructor(baseHost = '') {
    this.baseHost = baseHost;
  }

  async get<RespDataType>(url: string, params?: Record<string, string>): Promise<RespDataType> {
    let fullUrl = `${this.baseHost}${url.startsWith('/') ? '' : '/'}${url}`;
    if (params) {
      const sp = new URLSearchParams(params);
      fullUrl += `?${sp.toString()}`;
    }
    const resp = await fetch(fullUrl);
    if (!resp.ok) {
      throw new HttpError(resp);
    }
    const data: RespDataType = await resp.json();
    return data;
  }
}
