import { AppLocaleMessages } from '@/locales/en-us';

export const RuRuMessages: AppLocaleMessages = {
  'menu.about': 'О проекте',
  'menu.all-cameras': 'Все камеры',
  'menu.exit': 'Выйти',
  'about.title': 'О проекте',
  'about.body-html': '<p>Важная информация</p>',
  'components.loading.text': 'Загрузка...',
  'cameras.stream.loading': 'Загрузка потока камеры...',
  'cameras.alert.webrtc-isnot-supported': 'WebRTC не поддерживается вашим браузером.\nВы можете скачать {link}',
  'cameras.alert.webrtc-isnot-supported.google-chrome-link-text': 'Google Chrome здесь',
  'auth-form.errors.unknown-user': 'Неправильные логин или пароль',
};
