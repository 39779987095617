import { Profile } from '@/types/entities/Profile';

export class State {
  profile: Profile | null;

  constructor() {
    this.profile = null;
  }

  isAuthenticated() {
    return this.profile !== null;
  }
}
