
import {
  defineComponent,
  inject,
  ref,
  watch,
} from 'vue';
import adapter from 'webrtc-adapter';
import { JANUS_SERVER } from '@/config';
import Janus, { PluginHandle, JSEP } from '@/vendor/janus';
import { StreamDto } from '@/types/StreamDto';
import Loader from '@/components/Loader.vue';
import { useRoute } from 'vue-router';
import { State } from '@/services/State';

export default defineComponent({
  name: 'Cameras',
  components: { Loader },

  mounted() {
    this.startJanusSession(this.$refs.video as HTMLVideoElement);
  },

  unmounted() {
    if (this.janus) {
      this.janus.destroy();
    }
  },

  setup() {
    const isWebRtcSupported = Janus.isWebrtcSupported();
    const listOfStreams = ref<StreamDto[]>([]);
    const streamingRef = ref<PluginHandle>();
    const isConnected = ref<boolean>(false);
    const janusRef = ref<Janus>();
    const streamStatus = ref<string>('');
    const isLoading = ref<boolean>(true);
    const isStreamLoading = ref<boolean>(false);
    const selectedStream = ref<StreamDto | null>(null);
    const route = useRoute();
    const state = inject<State>('state');

    const isStreamSelected = (streamId: number) => selectedStream.value?.id === streamId;
    const handleCameraClick = (stream: StreamDto) => {
      if (selectedStream.value && selectedStream.value.id === stream.id) {
        return;
      }
      selectedStream.value = stream;
    };

    Janus.init({
      debug: true,
      dependencies: Janus.useDefaultDependencies({
        adapter,
      }),
      callback() {
        console.log('__DONE');
      },
    });

    function startJanusSession(videoElement: HTMLVideoElement) {
      // debugger;
      isLoading.value = true;
      let streaming: PluginHandle;
      const janus = new Janus({
        server: JANUS_SERVER,
        success() {
          console.log('SUCCESS');
          janus.attach({
            plugin: 'janus.plugin.streaming',
            success(pluginHandle) {
              streaming = pluginHandle;
              streamingRef.value = streaming;
              pluginHandle.send({
                message: { request: 'list' },
                success: (r) => {
                  const list = ((r.list || []) as StreamDto[]).sort(
                    (s1, s2) => (s1.id > s2.id ? 1 : -1),
                  );
                  const polygonIdFilter = state?.profile?.video_group;
                  listOfStreams.value = polygonIdFilter
                    ? list.filter((s) => s.id.toString(10).startsWith(polygonIdFilter.toString()))
                    : list;
                  if (selectedStream.value === null && listOfStreams.value?.length !== 0) {
                    let streamIWantToSelect: StreamDto | null = null;
                    if (route.params.id) {
                      streamIWantToSelect = listOfStreams.value
                        .find((s: StreamDto) => s.id === Number(route.params.id)) || null;
                    }
                    selectedStream.value = streamIWantToSelect || listOfStreams.value[0];
                  }
                  isLoading.value = false;
                },
              });
              console.log('__SUCCESS attach', this, pluginHandle);
            },
            onremotestream(stream) {
              Janus.attachMediaStream(videoElement, stream);
            },
            onmessage(message, jsep) {
              // debugger;

              console.log('ATTCH ___MESSAGE', message);
              const result = message.result;
              if (result) {
                if (result.status) {
                  streamStatus.value = result.status;
                }
              }

              if (message.error) {
                console.error('__MESSAGE ERROR', message.error);
              }
              if (jsep) {
                streaming.createAnswer(
                  {
                    jsep,
                    // We want recvonly audio/video and, if negotiated, datachannels
                    media: { audioSend: false, videoSend: false, data: true },
                    success(rJsep: JSEP) {
                      Janus.debug('Got SDP!', rJsep);
                      const body = { request: 'start' };
                      streaming.send({ message: body, jsep: rJsep });
                    },
                    error(error: any) {
                      Janus.error('WebRTC error:', error);
                    },
                  },
                );
              }
            },
            error(e) {
              console.log('__ERROR attach', this, e);
            },
          });
        },
        error(cause) {
          isConnected.value = false;
          // if (!janus.isConnected()) {
          // }
          setTimeout(() => startJanusSession(videoElement), 10000);
          console.log('__ERROR', cause);
        },
        destroyed() {
          console.log('destroy');
        },
      });
      janusRef.value = janus;
    }

    watch(selectedStream, async (val, oldVal) => {
      if (!streamingRef.value || !val) {
        return;
      }

      try {
        isStreamLoading.value = true;
        if (oldVal) {
          const stopBody = { request: 'stop' };
          await new Promise<void>((resolve) => {
            if (!streamingRef.value) {
              resolve();
              return;
            }
            streamingRef.value.send({ message: stopBody, success: resolve });
            streamingRef.value.hangup();
          });
        }

        const body = { request: 'watch', id: val.id };
        streamingRef.value.send({ message: body });
      } finally {
        isStreamLoading.value = false;
      }
    });

    return {
      isConnected,
      isWebRtcSupported,
      listOfStreams,
      streaming: streamingRef,
      janus: janusRef,
      streamStatus,
      isStreamLoading,
      isLoading,
      selectedStream,
      startJanusSession,
      isStreamSelected,
      handleCameraClick,
    };
  },
});
