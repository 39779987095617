import Cookies from 'js-cookie';

export class CookiesStorage {
  // eslint-disable-next-line class-methods-use-this
  set(key: string, value: string, expires: number | null) {
    Cookies.set(key, value, { expires: expires || undefined });
  }

  // eslint-disable-next-line class-methods-use-this
  get(key: string) {
    return Cookies.get(key);
  }

  // eslint-disable-next-line class-methods-use-this
  remove(key: string): void {
    Cookies.remove(key);
  }
}
