
import {
  computed,
  defineComponent,
  inject, onMounted,
  ref,
} from 'vue';
import LangSwitcher from '@/components/LangSwitcher.vue';
import AuthForm from '@/components/AuthForm.vue';
import { AuthenticationService } from '@/services/Auth';
import { State } from '@/services/State';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: { Loader, AuthForm, LangSwitcher },
  setup() {
    const isLoading = ref<boolean>(true);
    const authService = inject<AuthenticationService>('authService');

    const state = inject<State>('state');

    const isAuthenticated = computed(() => !!state?.isAuthenticated());
    onMounted(async () => {
      const profile = await authService?.checkCookieAuth();
      if (profile && state) {
        state.profile = profile;
      }
      isLoading.value = false;
    });

    const handleExitClick = async () => {
      if (!state || !authService) {
        return;
      }
      await authService.exit();
      state.profile = null;
    };

    const login = computed<string>(() => state?.profile?.login || '');

    return {
      isAuthenticated,
      state,
      isLoading,
      handleExitClick,
      login,
    };
  },
});
