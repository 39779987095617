
import {
  computed,
  defineComponent,
  inject,
  ref,
} from 'vue';
import { AuthenticationService } from '@/services/Auth';
import { State } from '@/services/State';

export default defineComponent({
  setup() {
    const state = inject<State>('state');
    const authService = inject<AuthenticationService>('authService');
    const isSending = ref<boolean>(false);
    const errorMessage = ref<string>('');
    const login = ref<string>('');
    const password = ref<string>('');

    const isValid = computed<boolean>(() => !!login.value.trim() && !!password.value.trim());

    const handleLogin = async () => {
      if (!state || !authService) {
        return;
      }
      isSending.value = true;
      errorMessage.value = '';
      try {
        const profile = await authService.check(login.value, password.value);
        if (!profile) {
          throw Error('unknown-user');
        }
        state.profile = profile;
        await authService.saveInCookies(profile);
      } catch (e) {
        console.error('Problems with login', e);
        errorMessage.value = e.message;
      } finally {
        isSending.value = false;
      }
    };

    return {
      handleLogin,
      state,
      login,
      password,
      errorMessage,
      isValid,
    };
  },
});

