import { createApp, reactive } from 'vue';
import 'webrtc-adapter';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faVideo,
  faLanguage,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createI18n } from 'vue-i18n';
import { DefaultMessages, EnUsMessages } from '@/locales/en-us';
import { RuRuMessages } from '@/locales/ru-ru';
import { AuthenticationService } from '@/services/Auth';
import { HttpTransport } from '@/services/HttpTransport';
import { State } from '@/services/State';
import { CookiesStorage } from '@/services/CookiesStorage';
import router from './routes';
import './assets/tailwind.css';
import './assets/components.css';
import App from './App.vue';

library.add(faCircleNotch, faVideo, faLanguage, faUser);

const i18n = createI18n<true>({
  locale: 'ru-ru',
  fallbackRoot: true,
  legacy: true,
  fallbackLocale: 'en-us',
  messageResolver: (obj: DefaultMessages | unknown, path: string) => {
    const msg = typeof obj === 'object' ? (obj as DefaultMessages)?.[path] : null;
    return msg || null;
  },
  messages: {
    'en-us': EnUsMessages,
    'ru-ru': RuRuMessages,
  },
});

createApp(App)
  .provide('authService', new AuthenticationService(new HttpTransport(), new CookiesStorage()))
  .provide('state', reactive(new State()))
  .use(router)
  .use(i18n)
  .component('fa-icon', FontAwesomeIcon)
  .mount('#app');
